@import '../../global.scss';

section.personalities {
    .personality-container{
        width: 80%;
        margin: 0 auto;
        .personalities-header{
            height: 20vh;
            h1{
                color: $primaryColor;
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 900;
                z-index: 100;
                position: relative;
            }
            .stroke{
                color: $white;
                position: absolute;
                -webkit-text-stroke: 1px $primaryColor;
                top: 0.05em;
                left: 0.05em;
                z-index: 0;
            }
            p{
                color: $textColor;
                font-size: 1.2rem;
            }
        }
    }
}

@media (max-width: 900px) {
    section.personalities{
        .personality-container{
            .personalities-header{
                height: auto;
                padding: 2rem 0;
                .header-feature-text{
                    margin-top: 5rem;
                    h1{
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}