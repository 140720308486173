@import "../../../global.scss";

.footer-section{
    overflow: hidden;
    height: 35vh;
    background: $primaryColor;
    border-top: 10px solid $optionalColor;
    position: relative;
    .footer-logo{
        position: absolute;
        left: 5vw;
        bottom: -3.5vw;
        img{
            height: 30vh;
        }
    }
    .footer-content-social{
        a{
            display: inline-flex;
            width: 2.6vw;
            height: 2.6vw;
            background: $white;
            color: $primaryColor;
            align-items: center;
            justify-content: center;
            margin: 0 0.4vw;
            font-size: 1.2vw;
            border-radius: 50%;
            text-decoration: none;
        }
    }
    .footer-content-menu{
        
        a{
            text-decoration: none;
            color: $white;
            padding: 0 1rem;
            border-right: 2px solid $white;
            height: fit-content;
            &:last-child{
                border-right: none;
            }
        }
    }
    .footer-content-bottom{
        p{
            color: $white;
        }
    }
}

@media screen and (max-width: 900px){
    .footer-section{
        height: auto;
        padding: 2rem 0;
        .footer-content-social{
            a{
                width: 30px;
                height: 30px;
                font-size: 1rem;
                margin: 0 5px;
            }
        }
        .footer-content-menu{
            a{
                display: inline-block;
                padding: 1rem 0;
                // border-right: 0;
                margin: 0 5px 0 5px;
                border: 0;
                font-size: 16px;
            }
        }
        .footer-content-bottom{
            p{
                color: $white;
                margin-top: -2rem;
            }
        }
    }
}