@import "../../global.scss";

.events-section{
    // height: 100vh;
    padding: 10% 5%;
    position: relative;
    @media screen and (max-width: 601px) {
        height: auto;
    }
    .events-container{
        .events-header{
            h2{
                color: $primaryColor;
                font-weight: bold;
                font-size: 4rem;
                text-transform: uppercase;
            }
            p{
                font-size: 1.4rem;
                color: $textColor;
            }
        }
        .events-body{
            height: 100%;
            a{
                text-decoration: none;
            }
            .card-data{
                .avaaz-card-img{
                    border-top-left-radius: 2rem;
                    border-top-right-radius: 2rem;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-top-left-radius: 2rem;
                        border-top-right-radius: 2rem;
                    }
                }
                .vaccine-card-img{
                    border-top-left-radius: 2rem;
                    border-top-right-radius: 2rem;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-top-left-radius: 2rem;
                        border-top-right-radius: 2rem;
                    }
                }
                .avaaz-card-footer{
                    background-color: $secondaryColor;
                    border-bottom-left-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                    h3{
                        color: $white;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: capitalize;
                    }
                }
                .vaccine-card-footer{
                    background-color: $primaryColor;
                    border-bottom-left-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                    h3{
                        color: $white;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    .corner-yellow-circle{
        height: 400px;
        width: 400px;
        background-color: $optionalColor;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @media screen and (max-width: 601px) {
            display: none;
        }
    }
}