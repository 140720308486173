@import "../../global.scss";

.video-section{
    padding: 5% 10%;
    position: relative;
    .laureate-video{
        .desk{
            display: block;
        }
        .mobile{
            display: none;
        }
    }
    .corner-blue-circle{
        height: 200px;
        width: 200px;
        background-color: $primaryColor;
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 100%;
        border-top-right-radius: 0;
    }
}

@media screen and (max-width: 500px){
    .video-section{
        .laureate-video{
            .desk{
                display: none;
            }
            .mobile{
                display: block;
            }
        }
    }
}