@import "../../global.scss";
.section-activities{
    background: $skyBlue;
    padding: 5% 5%;
    .activity-container{
        height: 80%;
        width: 80%;
        margin: 0 auto;
        img{
            height: 13vh;
        }
        p{
            margin-top: 1.5rem;
            color: $textColor;
            font-weight: bold;
            font-size: 1.3vw;
        }
    }
}

@media screen and (max-width: 900px){
    .section-activities{
        .activity-container{
            p{
                font-size: 1.1rem;
            }
        }
    }
}