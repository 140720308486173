@import "../../global.scss";

.section-laureate{
    // min-height: 1000px;
    padding: 10% 5%;
    background-color: $skyBlue;
    position: relative;
    .laureate-container{
        // height: 65vh;
        margin: 0 auto;
        width: 80%;
        z-index: 500;
    }
    .corner-red-circle{
        height: 300px;
        width: 300px;
        background-color: $secondaryColor;
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

@media screen and (max-width: 900px){
    .section-laureate{
        height: auto;
        padding: 2rem 0;
        .laureate-container{
            height: 100%;
            width: 90%;
        }
    }
}