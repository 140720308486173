@import '../../global.scss';


#quote_container {
    text-align: left;
    vertical-align: middle;
    padding:0 5rem 0 5rem;
    margin-top: 25%;
    height: 100%;
}
#testimonial {

    font-size: 1.2rem;
    font-style: italic;
    font-weight: 300;
    color: $primaryColor;
}

#name {
    font-weight: 500;
    font-style: italic;
    color: $textColor;
    margin-top: 1.2em;
    margin-bottom: 0em;
    &:before {
        content: '-';
    }
}

#designation {
    font-weight: 300;
    font-style: italic;
    margin-top: 0;
}

#lower_text{
    bottom: 10%;
}