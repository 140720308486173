@import '../../global.scss';

.carousel {
    z-index:1;
    position: relative;
    bottom: -10%;
    outline: none;
    @media screen and (max-width: 901px){
        flex-direction: column-reverse;
        align-items: center;
    }
}

.slide_d {
    overflow: hidden;
    img {
        object-fit: cover;
        object-position: center;
        object-position: 50%;
    }
    div p{
        width: 45ch;
    }
}

.testimonials {
    background-color: #E6F6FF;
    padding: 7% 10% 5% 10%;
    position: relative;
    overflow: hidden;
    padding-top: 6rem;
    @media screen and (max-width: 901px){
        height: auto;
        padding: 3rem 0;
    }
    @media screen and (min-width: 1441px) {
        padding: 8% 10% 8% 10%;
    }
    .slick-dots { 
        bottom: -35px;
        li {
            margin-left: 2px !important;
            margin-right: 2px !important;
            button:before {
                font-size: 12px;
                color: $white;
                opacity: 0.5;
            }
        }
        li.slick-active {
            button:before {
                color: $white;
                opacity: 1;
            }
        }
    }
}

#circle {
    width: 400px;
    height: 400px;
    background-color: #E19D53;
    position: absolute;
    right: 0%;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 0;
    z-index: 0;
    @media screen and (max-width: 601px) {
        display: none;
    }
}

