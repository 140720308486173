@import "../../global.scss";

.about-section{
    padding: 3% 15% 7% 15%;
    position: relative;
    .about-container{
        // height: 80vh;
        // width: 80%;
        z-index: 500;
        .about-img-container{
            height: 80%;
            width: 80%;
            border-radius: 25px;
            box-shadow: 10px 10px 5px 0 rgba(0,0,0,0.1);
            &::after{
                content: "";
                position: absolute;
                width: 15rem;
                height: 15rem;
                border-radius: 50%;
                background-color: $skyBlue;
                top: -5%;
                right: -5%;
                z-index: -1;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 5px solid $optionalColor;
                border-radius: 25px;
                z-index: 500;
            }
        }
        .about-data-container{
            .declare-header{
                color: $textColor;
                font-size: 2.4rem;
            }
            .global-common-header{
                color: $secondaryColor;
                font-size: 2.4rem;
            }
            p{
                font-size: 2.6vmin;
                color: $textColor;
                width: 80%;
            }
            a{
                text-decoration: none;
                color: $primaryColor;
                font-weight: bold;
            }
        }
    }
    .corner-blue-circle{
        height: 200px;
        width: 200px;
        background-color: $primaryColor;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .corner-red-circle{
        height: 300px;
        width: 300px;
        background-color: $secondaryColor;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 100%;
    }
}

@media screen and (max-width: 900px){
    .about-section{
        // padding: 2rem 0;
        padding: 5% 5% 7% 5%;
        // height: 800px;
        .about-container{
            width: 85%;
            height: auto;
            padding-bottom: 1rem;
            .about-data-container{
                .declare-header{
                    font-size: 1.5rem;
                }
                .global-common-header{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 1rem;
                    color: $textColor;
                    width: 100%;
                }
            }
        }
    }
    .corner-blue-circle{
        display: none;
    }
    .corner-red-circle{
        display: none;
    }
}