@import "../../global.scss";

.join-the-cause-section{
    height: 100vh;
    background-image: url("../../assets/yunus-speech.png");
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: center;
    position: relative;
    .join-the-cause-content{
        position: absolute;
        text-align: right;
        top: 10rem;
        right: 5.2rem;
        h2{
            font-size: 3.5rem;
            text-transform: uppercase;
            color: $white;
            font-weight: bold;
        }
        p{
            color: $white;
            font-size: 1.6rem;
        }
    }
    .join-the-cause-btn{
        margin-right: 5rem;
        button{
            outline: none;
            border: none;
            padding: 12px 36px;
            background: $secondaryColor;
            color: $white;
            font-size: 1.4vw;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 16px;
        }
    }
}

@media screen and (max-width: 900px){
    .join-the-cause-section{
        background-position: center;
        box-shadow: inset 500px 500px rgba($color: #000000, $alpha: .3);
        height: 600px;
        .join-the-cause-content{
            margin-top: 10rem;
            h2{
                font-size: 2rem;
            }
            p{
                font-size: 16px;
            }
        }
        .join-the-cause-btn{
            margin-top: 25rem;
            button{
                font-size: 16px;
            }
        }
    }
}