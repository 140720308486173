@import "../../../global.scss";

.pledge-route-section{
    .pledge-route-container{
        width: 80%;
        margin: 0 auto;
        .main-pledge-header-section{
            height: 20vh;
            h1{
                color: $primaryColor;
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 900;
                z-index: 100;
                position: relative;
            }
            .stroke{
                color: $white;
                position: absolute;
                -webkit-text-stroke: 2px $primaryColor;
                top: 0.34vw;
                left: 0.2vw;
                z-index: 0;
            }
            p{
                color: $textColor;
                font-size: 1.2rem;
            }
            select{
                width: 100%;
                padding: 10px 20px;
                border: 1px solid $border;
                border-radius: 10px;
                margin-top: 1rem;
                color: $textColor;
                outline: none;
                &:focus{
                    box-shadow: none;
                }
                option{
                    padding: 1rem 0.5rem;
                }
            }
        }
        .main-pledge-body-section{
            .pledge-people-image{
                height: 90px;
                width: 90px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }
            }
            h5{
                color: $textColor;
                font-weight: bold;
            }
            p{
                color: #777;
            }
        }
        .main-pledge-footer-section{
            padding: 5% 0;
        }
    }
}

@media screen and (max-width: 900px) {
    .pledge-route-section{
        .pledge-route-container{
            .main-pledge-header-section{
                height: auto;
                padding: 2rem 0;
                margin-top: 2rem;
                h1{
                    font-size: 2.5rem;
                }
                .stroke{
                    display: none;
                }
            }
        }
    }
}