@import "../../global.scss";

.partners-section{
    padding: 10%;
    background-color: $skyBlue;
    .partners-section-header{
        h2{
            color: $primaryColor;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 3.5vw;
        }
    }
    .partners-section-body{
        width: 60%;
        margin: 0 auto;
        height: 60%;
        img{
            width: 100%;
            // height: 17vh;
        }
    }
}

@media screen and (max-width: 900px){
    .partners-section{
        height: auto;
        .partners-section-header{
            h2{
                font-size: 2.5rem;
            }
        }
        .partners-section-body{
            width: 100%;
            flex-direction: column;
        }
    }
}