@import "../../../global.scss";

.laureate-content-container {
    p{
        color: $textColor;
        font-size: 1.3vw;
    }
    h3{
        text-transform: uppercase;
        font-size: 5vmin;
        color: $primaryColor;
        font-weight: 900;
    }
    h2{
        font-size: 3.8vw;
        font-weight: bold;
        color: $primaryColor;
    }
    .laureate-count{
        color: $secondaryColor;
    }
    h5{
        color: $textColor;

    }
    h4{
        color: $textColor;
        font-size: 4vmin;
        font-weight: 900;
        text-transform: uppercase;
    }
    a{
        text-decoration: none;
        color: $primaryColor;
        font-size: 1.2vw;
        text-transform: capitalize;
    }
    .yellow{
        color: $optionalColor;
    }
}
@media screen and (max-width: 900px){
    .laureate-content-container{
        p{
            font-size: 16px;
        }
        h3{
            font-size: 1.8rem;
        }
        h2{
            font-size: 2.5rem;
        }
        a{
            font-size: 16px;
        }
        h4{
            font-size: 7vmin;
            padding-bottom: 1rem;
        }
    }  
}

// @media screen and (min-width: 1441px){
//     .laureate-content-container{
//         margin-top: -1rem;
//     }  
// }