@import "../../../global.scss";

.join-the-cause-router-section{
    height: 40vh;
    background-color: $skyBlue;
    h2{
        color: $primaryColor;
        font-size: 3.5rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    p{
        font-size: 1.4rem;
        color: $textColor;
    }
    button{
        padding: 14px 34px;
        outline: none;
        border: none;
        background-color: $secondaryColor;
        font-weight: bold;
        color: $white;
        border-radius: 15px;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 900px) {
    .join-the-cause-router-section{
        h2{
            font-size: 2rem;
        }
        p{
            font-size: 1rem;
        }
    }
}