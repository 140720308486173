html,body{
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

$primaryColor: #3A8FC5;
$secondaryColor: #D55363;
$optionalColor: #E19D53;
$white: #ffffff;
$textColor: #4D4D4D;
$skyBlue: #E6F6FF;
$border: #ccc;

$breakpointTablet: 900px;
$breakpointMobile: 500px;

// mobile logo
.navbar-logo-mobile {
  display: none;
  position: absolute;

  @media screen and (max-width: 900px) {
    width: fit-content;
    display: block;
    top: 2%;
    left: 3%;
    img {
      margin-top: 1.5rem;
      margin-left: 1rem;
      width: 50%;
    }
  }
}