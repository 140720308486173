@import "../../../global.scss";

.form-route-section{
    .form-route-container{
        width: 80%;
        margin: 0 auto;
        .main-form{
            height: auto;
            padding: 2rem 0;
            .main-form-header-section{
                padding: 2rem 0;
                .main-form-header{
                    h1{
                        color: $primaryColor;
                        font-size: 4rem;
                        text-transform: uppercase;
                        font-weight: 900;
                        z-index: 100;
                        position: relative;
                    }
                    .stroke{
                        color: $white;
                        position: absolute;
                        -webkit-text-stroke: 1px $primaryColor;
                        top: 0.05em;
                        left: 0.05em;
                        z-index: 0;
                    }
                    p{
                        color: $textColor;
                        font-size: 1.2rem;
                    }
                }
            }
            .main-form-body-section{
                form{
                    h5{
                        color: $textColor;
                        font-weight: 400;
                        font-size: 1rem;
                    }
                    .fa-star-of-life{
                        font-size: 0.5rem;
                        color: $secondaryColor;
                    }
                    label{
                        display: block;
                        font-weight: bold;
                        color: $textColor;
                        font-size: 1.3rem;
                    }
                    input, select {
                        width: 100%;
                        padding: 10px 20px;
                        border: 1px solid $border;
                        border-radius: 10px;
                        margin-top: 1rem;
                        color: $textColor;
                        outline: none;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                    .form-file-upload{
                        h4{
                            display: block;
                            font-weight: bold;
                            color: $textColor;
                            font-size: 1.3rem;
                        }
                        p{
                            font-weight: 400;
                            color: #666;
                            margin-top: 2rem;
                        }
                        input{
                            width: 40%;
                            margin-top: 2rem;
                            &::-webkit-file-upload-button{
                                background-color: $primaryColor;
                            }
                        }
                    }
                    .form-check-upload{
                        h4{
                            display: block;
                            font-weight: bold;
                            color: $textColor;
                            font-size: 1.3rem;
                        }
                        p{
                            font-weight: 400;
                            color: #666;
                            margin-top: 2rem;
                        }input{
                            padding: 0 0;
                            width: 32px;
                            height: 32px;
                            border-radius: 8px;
                            margin-top: 0;
                            &:checked{
                                background-color: $primaryColor;
                            }
                        }
                    }
                    .submit-area{
                        button{
                            outline: none;
                            border: none;
                            padding: 10px 56px;
                            font-size: 1.2rem;
                            text-transform: uppercase;
                            background-color: #B2B2B2;
                            color: $textColor;
                            font-weight: bold;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .form-route-section{
        .form-route-container{
            width: 85%;
            .main-form{
                .main-form-header-section{
                    margin-top: 4rem;
                    padding-bottom: 0;
                    .main-form-header{
                        h1{
                            font-size: 2rem;
                        }
                        .stroke{
                            display: block;
                        }
                        p{
                            font-size: 1rem;
                        }
                    }
                }
                .main-form-body-section{
                    form{
                        label{
                            font-size: 1rem;
                            font-weight: 600;
                            margin-top: 1rem;
                        }
                        .form-file-upload{
                            input{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}