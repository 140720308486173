@import '../../global.scss';

.people-description{
    border: 5px solid rgba(1,1,1,0);
    position: relative;
    padding-left: 0;
    img{
        
        width: 280px;
        height: 200px;
        border-radius: 15px;
        object-fit: cover;
        z-index: 2;
        border: 2px solid darkcyan;
        background-color: white;
    }
    .people-description-data{
        @media screen and (min-width: 850px){
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        // flex;
        justify-content: flex-start;
        align-items: flex-end;
        }
        p{
            text-align: left;
            // white-space: pre-wrap;
            position: relative;
            z-index: -1;
            margin: 0;
            left: -35px;
            padding: 0.5em;
            padding-left: 3em;
        }
        p:nth-child(1){
            border: 1px solid $secondaryColor;
            border-radius: 10px;
            font-weight: 400;
            font-size: 0.8rem;
            // width: 50%;
        }
        p:nth-child(2){
            border: 1px solid $primaryColor;
            border-radius: 10px;
            font-weight: 300;
            // width: 50%;
            font-size: 0.8rem;
        }
        p ~ p {
            margin-top: 0.8em;
        }
    }
}

@media (max-width: 900px) {
    .people-description{
        text-align: center;
        img{
            width: 100%;
        }
        .people-description-data{
            p:nth-child(1){
                text-align: left;
                margin-top: 1rem;
                margin-left: 4rem;
                padding-left: 0;
                border: 0;
                font-size: 18px;
                font-weight: 700;
                color: $primaryColor;
            }
            p:nth-child(2){
                text-align: left;
                margin-top: 0rem;
                margin-left: 4rem;
                padding-left: 0;
                border: 0;
                font-size: 16px;
            }
        }
    }
}