@import "../../../global.scss";

@mixin imageSize {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.people-container{
    height: 70vh;
    .left-side-people{
        .left-person-one{
            height: 25vh;
            width: 60%;
            position: absolute;
            border-radius: 15px;
            top: 10vh;
            right: 0;
            overflow: hidden;
            .slideshowSlider{
                white-space: nowrap;
                transition: ease 1000ms;
                .slide{
                    display: inline-block;
                    height: 25vh;
                    width: 100%;
                    img{
                        @include imageSize() 
                    }
                }
            }
        }
        .left-person-two{
            height: 30vh;
            width: 40%;
            background-color: $primaryColor;
            position: absolute;
            border-radius: 15px;
            top: 37vh;
            right: 0;
            h2{
                color: $white;
                font-weight: 900;
                font-size: 2.8rem;
            }
            p{
                color: $white;
                font-size: 1.2rem;
                text-transform: capitalize;
            }
            a{
                text-decoration: none;
            }
        }
        .left-person-three{
            height: 22vh;
            width: 40%;
            position: absolute;
            border-radius: 15px;
            top: 37vh;
            left: 5vw;
            overflow: hidden;
            .slideshowSlider{
                white-space: nowrap;
                transition: ease 1000ms;
                .slide{
                    display: inline-block;
                    height: 22vh;
                    width: 100%;
                    img{
                        @include imageSize() 
                    }
                }
            }
            img{
                @include imageSize() 
             }
        }
    }
    .right-side-people{
        .right-person-one{
            height: 40vh;
            width: 50%;
            position: absolute;
            border-radius: 15px;
            top: 15vh;
            left: 1vw;
            img{
                @include imageSize() 
            }
        }
        .right-person-two{
            height: 15vmin;
            // padding: 3% 5% 1% 5%;
            width: 38%;
            background-color: $secondaryColor;
            position: absolute;
            border-radius: 15px;
            top: 5vh;
            right: 1.5vw;
            h2{
                color: $white;
                font-weight: 900;
                font-size: 2.8rem;
                margin-top: 2%;
            }
            p{
                color: $white;
                font-size: 1.2rem;
                text-transform: capitalize;
            }
            a{
                text-decoration: none;
            }
        }
        .right-person-three{
            height: 22vh;
            width: 38%;
            position: absolute;
            border-radius: 15px;
            top: 22vh;
            right: 1.5vw;
            overflow: hidden;
            .slideshowSlider{
                white-space: nowrap;
                transition: ease 1000ms;
                .slide{
                    display: inline-block;
                    height: 22vh;
                    width: 100%;
                    img{
                        @include imageSize() 
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px){
    .people-container{
        height: auto;
        .left-side-people{
            display: none;
            
        }
        .right-side-people{
            display: none;
        }
    }
}