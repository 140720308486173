@import "../../../global.scss";

.events-route-section{
    .events-route-section-container{
        width: 80%;
        margin: 0 auto;
        .ongoing-events{
            .ongoing-events-header{
                h1{
                    color: $secondaryColor;
                    font-size: 3rem;
                    text-transform: uppercase;
                    font-weight: 900;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        margin-top: 0.4em;
                        margin-left: 0.4rem;
                        background: $secondaryColor;
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                        box-shadow: 0 0 0 0 rgba(213, 83, 99, 1);
                        transform: scale(1);
                        animation: pulse 1.5s infinite;
                    }
                }
            }
            .ongoing-events-body{
                .ongoing-events-body-data{
                    a{
                        text-decoration: none;
                    }
                    img{
                        border-radius: 15px;
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                    }
                    h5{
                        color: $textColor;
                        font-weight: 900;
                    }
                    p{
                        color: $textColor;
                    }
                }
            }
        }
        .events-route-header{
            h1{
                color: $primaryColor;
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 900;
                z-index: 100;
                position: relative;
            }
            .stroke{
                color: $white;
                position: absolute;
                -webkit-text-stroke: 1px $primaryColor;
                top: 0.05em;
                left: 0.05em;
                z-index: 0;
            }
            img{
                width: 100%;
                height: 400px;
                object-fit: cover;
            }
            h4{
                color: $primaryColor;
                font-weight: bold;
                font-size: 1.8rem;
            }
            p{
                margin-top: 2rem;
            }
            a{
                text-decoration: none;
                color: $primaryColor;
                text-transform: capitalize;
            }
        }
        .events-route-footer{
            a{
                text-decoration: none;
            }
            h4{
                color: $optionalColor;
                font-weight: bold;
                text-transform: capitalize;
            }
            img{
                width: 100%;
                border-radius: 20px 20px 0 0;
            }
            p{
                font-size: 0.9rem;
                margin-top: 1.5rem;
                text-decoration: none;
                color: $textColor;
            }
            .box-footer{
                background-color: $secondaryColor;
                border-radius: 0 0 20px 20px;
                h4{
                    color: $white;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 900px){
    .events-route-section{
        .events-route-section-container{
            .ongoing-events{
                height: auto;
                padding: 2rem 0;
                margin-top: 2rem;
            }
            .events-route-header{
                height: auto;
                margin-top: 2rem;
                h1{
                    font-size: 2rem;
                }
                img{
                    height: 200px;
                }
            }
        }
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(213, 83, 99, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(213, 83, 99, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(213, 83, 99, 0);
	}
}