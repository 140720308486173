@import "../../global.scss";
@mixin imageSize {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

header{
    height: 100vh;
    background-image: url("../../assets/vaccine-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .header-container{
        margin-left: 10vw;
        .header-feature-text{
            h1{
                color: $primaryColor;
                font-size: 5rem;
                text-transform: uppercase;
                font-weight: 900;
                z-index: 100;
                position: relative;
                line-height: 4.5rem;
            }
            .stroke{
                color: $white;
                position: absolute;
                -webkit-text-stroke: 1px $primaryColor;
                top: 0.05em;
                left: 0.05em;
                z-index: 0;
            }
            .red-million{
                font-weight: bold;
                color: $secondaryColor;
                font-size: 5vmin;
            }
            .signed-bold{
                font-weight: bold;
                color: $textColor;
            }
            h3{
                color: $secondaryColor;
                font-weight: 900;
                font-size: 2.4rem;
            }
            h4{
                color: $textColor;
                font-weight: 900;
                text-transform: capitalize;
                font-size: 5.5vmin;
            }
            p{
                font-size: 3.5vmin;
                color: $textColor;
                margin-top: 2rem;
                width: 100%;
            }
            .mobile-data-container{
                .mobile-country{
                    display: none;
                }
                .mobile-signatories{
                    display: none;
                }
                a{
                    text-decoration: none;
                }
            }
            // mobile slider
            .mobile-slider-container{
                display: none;
                height: 300px;
                width: 90%;
                border-radius: 15px;
                overflow: hidden;
                .slideshowSlider{
                    white-space: nowrap;
                    transition: ease 1000ms;
                    .slide{
                        display: inline-block;
                        height: 300px;
                        width: 100%;
                        img{
                            @include imageSize() 
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    header{
        height: auto;
        padding-bottom: 2rem;
        .header-container{
            .header-feature-text{
                margin-top: 10rem;
                padding-left:0;
                h1{
                    font-size: 3.5rem;
                    line-height: 3rem;
                }
                .stroke{
                    display: block;
                }
                h3{
                    font-size: 1.5rem;
                    padding-bottom: 1rem;
                }
                h4{
                    font-size: 2.5rem;
                }
                p{
                    font-size: 7vmin;
                }
                .red-million{
                    font-size: 8vmin;
                }
                .mobile-data-container{
                    width: 100%;
                    .mobile-country{
                        border-radius: 10px;
                        background-color: $secondaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        height: auto;
                        padding: 6% 10%;
                        h5{
                            font-weight: 900;
                            color: $white;
                            margin-top: 5%;
                            font-size: 2.5rem;
                        }
                        h6{
                            color: $white;
                        }
                    }
                    .mobile-signatories{
                        border-radius: 10px;
                        background-color: $primaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        height: auto;
                        padding: 5% 10%;
                        h5{
                            font-weight: 900;
                            color: $white;
                            margin-top: 5%;
                            font-size: 2.5rem;
                        }
                        h6{
                            color: $white;
                        }
                    }
                }
                // mobile slider
                .mobile-slider-container{
                    display: block;
                }
            }
        }
        .corner-blue-circle{
            display: none;
        }
    }
}


