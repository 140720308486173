@import "../../../global.scss";

.laureate-people-container{
    // margin-top: 5rem;
    .people-image{
        height: 11rem;
        margin-top: 1rem;
        border-radius: 1.2rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1.2rem;
        }
    }
}

@media screen and (max-width: 900px){
    .laureate-people-container{
        padding: 0;
        .people-image{
            height: 80px;
            width: 100px;
            img{
                border-radius: 12px;
            }
        }
    }
}