@import "../../../global.scss";

.navbar {
  height: 20vh;
  background-color: inherit;
  .navbar-container {
    width: 80%;
    margin: 0 auto;
    .navbar-logo {
      img {
        height: 80px;
      }
    }
    .navbar-menu {
      a {
        text-decoration: none;
        color: #4d4d4d;
        margin: 0 10px;
        padding: 8px 18px;
        background: inherit;
        border: 1px solid rgba(255, 255, 255, 0);
        border-radius: 5px;
        font-weight: 400;
        font-size: 18px;
        transition: 0.3s;
        &:hover {
          background: #fff;
          border: 1px solid #3a8fc5;
          border-radius: 5px;
          color: #3a8fc5;
        }
      }
      .navActive {
        background: #fff;
        border: 1px solid #3a8fc5;
        border-radius: 5px;
        color: #3a8fc5;
      }
      .join-btn {
        padding: 8px 28px;
        background: #d55363;
        border: 1px solid #d55363;
        color: #fff;
      }
    }
    .cross-icon {
      display: none;
      cursor: pointer;
    }
  }
  .menu-icon {
    display: none;
    cursor: pointer;
  }
}

@media (max-width: $breakpointTablet) {
  .navbar {
    background-color: $white;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    position: absolute;
    z-index: 1500;
    transition: 0.3s;
    .navbar-container {
      flex-direction: column;
      .navbar-logo {
        img {
          height: 60px;
        }
      }
      .navbar-menu {
        a {
          display: block;
          text-align: center;
          margin-top: 2rem;
          color: $primaryColor;
        }
      }
      .cross-icon {
        position: absolute;
        top: 10%;
        left: 10%;
        color: $primaryColor;
        font-size: 30px;
        display: block;
      }
    }
    .menu-icon {
      position: absolute;
      right: -90%;
      top: 6%;
      font-size: 30px;
      display: block;
      color: $primaryColor;
    }
  }
}

