@import "../../../global.scss";

.partners-route-container{
    width: 80%;
    margin: 0 auto;
    .partners-route-header{
        h1{
            color: $primaryColor;
            font-size: 4rem;
            text-transform: uppercase;
            font-weight: 900;
            z-index: 100;
            position: relative;
        }
        .stroke{
            color: $white;
            position: absolute;
            -webkit-text-stroke: 1px $primaryColor;
            top: 0.05em;
            left: 0.05em;
            z-index: 0;
        }
    }
    .partners-route-body{
        height: auto;
        padding: 1rem 0 5rem 0;
        .partners-route-brands{
            height: 100%;
            img{
                height: 14vh;
            }
            p{
                color: $textColor;
                font-size: 1rem;
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .partners-route-container{
        padding-top: 4rem;
        .partners-route-header{
            h1{
                font-size: 2rem;
            }
            .stroke{
                display: block;
            }
        }
    }
    .partners-route-body{
        height: auto;
        p{
            margin-top: 1rem;
        }
    }
}