#headshot_container {
    display: flex;
    justify-content: center;
    img {
        height: 50vh;
        width: 100%;
        object-fit: cover;
        border: 6px solid #D55363;;
        border-radius: 40px;

    }
}

@media screen and (max-width:500px){
    #headshot_container {
        img {
            width: 60%;
    
        }
    }
}